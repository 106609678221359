import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormatedType } from "./types";
import { sortSubjectsFunction } from "./util";

type ListSubjectProps = {
  selectedItems: FormatedType[];
  onChange: (data: FormatedType) => void;
  enabled?: boolean;
};

export default function ListSubject({ selectedItems, onChange, enabled = true }: ListSubjectProps) {
  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      <Table>
        <TableHead sx={{ display: "table-header-group" }}>
          <TableRow>
            <TableCell>Grupo</TableCell>
            <TableCell align="left">Posição</TableCell>
            <TableCell align="left">Nome</TableCell>
            <TableCell align="left">CH</TableCell>
            <TableCell align="right">Remover</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedItems?.sort(sortSubjectsFunction)?.map((item, index) => (
            <TableRow key={item.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell>{item?.group || " "}</TableCell>
              <TableCell align="left">{item.position}</TableCell>
              <TableCell align="left">{item.name}</TableCell>
              <TableCell align="left">{item.hours}</TableCell>
              <TableCell align="right">
                <IconButton
                  disabled={!enabled}
                  aria-label="delete"
                  size="medium"
                  onClick={() => onChange(item)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
