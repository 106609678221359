import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { useCallback } from "react";
import { SchemaData } from "./types";
import { CreateGql, CREATE_QUERY } from "../queries/create";
import { ApiCourseRequest } from "data/queries/ApiCourse";
import { useNavigate } from "react-router-dom";

export default function useCreate() {
  const navigate = useNavigate();
  const handleSave = useCallback(async (state: JsonFormsStateProps<SchemaData>) => {
    const { startDate, contentJson, institution, ...data } = state?.data;

    const response = await ApiCourseRequest<CreateGql>({
      query: CREATE_QUERY,
      variables: {
        data: {
          ...data,
          institution: !institution ? null : institution.toUpperCase(),
          contentJson: !contentJson ? null : contentJson,
          startDate: startDate ? new Date(startDate) : null,
        },
      },
    });

    if (response?.data?.errors) {
      throw response.data.errors[0];
    }
    if (!response?.data?.data || !response?.data?.data?.createCourse?.id) {
      throw new Error("Ops. Sem resposta!");
    }

    navigate(`/ecommerce/cursos/${response.data.data.createCourse.id}/info`);

    return response;
  }, []);

  return {
    handleSave,
  };
}
