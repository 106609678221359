import { Fragment } from "react";
import { Accordion } from "./Accordion";
import { BasicAccordionProps } from "./types";

export const BasicAccordion = ({
  sx,
  items,
  icon = null,
}: BasicAccordionProps) => {
  return (
    <Fragment>
      {items.map((item, index) => (
        <Accordion
          key={index.toString()}
          title={item?.title}
          content={item?.content}
          sx={sx}
          icon={icon}
          hide={item?.hide}
        />
      ))}
    </Fragment>
  );
};
