import AddIcon from "@mui/icons-material/Add";

import { AccordionSummary } from "./styles";
import { AccordionSummaryComponetProps } from "./types";

export const AccordionSummaryComponet = ({
  children,
  hidden,
  icon,
  onClick,
  sx,
}: AccordionSummaryComponetProps) => {
  const _handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <AccordionSummary sx={sx} onClick={_handleClick} open={hidden} icon={icon}>
      {children}
      {icon ? icon : <AddIcon />}
    </AccordionSummary>
  );
};
