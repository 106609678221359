export const slugsStaticPagesEnum = {
  about: "about",
  consultregistrationandmec: "consultregistrationandmec",
  enade: "enade",
  evaluationcommittee: "evaluationcommittee",
  formsofentry: "formsofentry",
  home: "home",
  internationalrelations: "internationalrelations",
  pedagogicalproposal: "pedagogicalproposal",
  policies: "policies",
  publications: "publications",
  suporte: "suporte",
  virtualtour: "virtualtour",
  workwithus: "workwithus",
};
