import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import LinkCell from "components/CRPComponents/Tables/components/LinkCell";
import { ListProps } from "./types";
import { GET_ALL_QUERY, GetAllGql } from "./queries/getAll";
import { ApiCourseWithSetData } from "data/queries/ApiCourse";
import TableSubject from "components/CRPComponents/Tables/subjectTable";

function List(props: ListProps) {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <TableSubject<GetAllGql>
        initialVariables={{
          page: 1,
          perPage: 10,
          search: null,
          ...props.initialVariables,
        }}
        useDefaultFilter={false}
        path={`/ecommerce/materias`}
        query={GET_ALL_QUERY}
        formatRows={(data) => data.Subjects.items}
        formatPagination={(data) => data.Subjects.paginationInfo}
        title="NOVA MATÉRIA"
        ApiSetData={ApiCourseWithSetData}
        columnsTable={[
          {
            Header: "id",
            accessor: "id",
            Cell: ({ value }: any) => (
              <LinkCell href={`/ecommerce/materias/${value}`} value={value} />
            ),
          },
          {
            Header: "Grupo",
            accessor: "group",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Posição",
            accessor: "position",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Nome",
            accessor: "name",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
        ]}
        {...props}
      />
      {props.showNavBar && <Footer />}
    </DashboardLayout>
  );
}

export default List;
