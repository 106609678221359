import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const UPDATE_QUERY = print(gql`
  mutation UpdateMe(
    $email: String
    $cpf: String
    $firstName: String
    $lastName: String
    $phone: String
    $birthDate: DateTimeISO
    $residentialPhone: String
    $googleUid: String
  ) {
    updateMe(
      email: $email
      cpf: $cpf
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      birthDate: $birthDate
      residentialPhone: $residentialPhone
      googleUid: $googleUid
    ) {
      id
      firstName
      email
      role {
        id
        name
        description
        permissions {
          id
          name
          description
        }
      }
      permissions {
        id
        name
        description
      }
      lastName
      userName
      phone
      cpf
      rg
      birthDate
      residentialPhone
      active
      academicEmail
      googleUid
    }
  }
`);

export interface UpdateGql {
  user: {
    id: string;
    firstName: string;
    email: string;
    role: {
      id: number;
      name: string;
      description: string;
      permissions: {
        id: number;
        name: string;
        description: string;
      };
    };
    permissions: [
      {
        id: number;
        name: string;
        description: string;
      }
    ];
    lastName: string;
    userName: string;
    phone: string;
    cpf: string;
    rg: string;
    birthDate: string;
    residentialPhone: string;
    active: string;
    academicEmail: string;
    googleUid: string;
  };
}
