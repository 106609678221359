import { useCallback, useState } from "react";
import { DeleteButton, DeleteButtonProps } from "../../DeleteButton";
import { ApiCMSRequest } from "data/ApiCMS";
import { MUTATION_DELETE_FAQ, ResponseDeleteFaq } from "data/queries/ApiCMS/Faq/MutationDeleteFaq";

export interface ButtonDeleteFaqProps
  extends Omit<DeleteButtonProps<number>, "propDelete" | "handleDelete"> {
  onDeleteSuccess?: (id: number) => void;
  onDeleteError?: (err: Error) => void;
  deleteFaqId: number;
}

export function ButtonDeleteFaq({
  onDeleteSuccess,
  onDeleteError,
  deleteFaqId,
  ...props
}: ButtonDeleteFaqProps) {
  const [loading, setLoading] = useState(false);
  const handleDelete = useCallback(async () => {
    setLoading(true);
    try {
      const response = await ApiCMSRequest<ResponseDeleteFaq>({
        query: MUTATION_DELETE_FAQ,
        variables: { deleteFaqId },
      });

      if (!response?.data?.data?.deleteFaq?.id) {
        if (response.data?.errors?.[0]) throw response.data?.errors?.[0];
        throw new Error(JSON.stringify(response?.data || "Something wrong"));
      }

      onDeleteSuccess?.(response.data.data.deleteFaq?.id);
    } catch (error: any) {
      console.error(error);
      setLoading(false);
      onDeleteError?.(error);
    }
  }, [deleteFaqId]);

  if (!deleteFaqId) return null;
  return (
    <DeleteButton
      {...props}
      propDelete={deleteFaqId}
      handleDelete={handleDelete}
      loading={loading}
    />
  );
}

export default ButtonDeleteFaq;
