import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Matoerial Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { columnsTable } from "./data";
import SearchInputControled from "components/CRPComponents/Forms/components/SearchInputControled";
import DataTableControled from "examples/Tables/DataTableControled";
import FilterMenu from "./data/FilterMenu";
import { ApiCMSWithSetData, DataState } from "data/ApiCMS";
import { QUERY_FAQS, ResponseFaqs, VariablesTypeFaqs } from "data/queries/ApiCMS/Faq/QueryFaqs";
import type { userPermissionsProps } from "hooks/types";
import { userPermissions } from "hooks/Permissions";

function FaqsTable({
  path,
  siteId,
  actionsPermission,
}: {
  path: string;
  siteId: number;
  actionsPermission: userPermissionsProps;
}): JSX.Element {
  const permission = userPermissions(actionsPermission);
  const [variables, setVariables] = useState<VariablesTypeFaqs>({
    page: 1,
    perPage: 10,
    search: null,
    status: null,
    siteId: siteId,
  });
  const [{ error, loading, data }, setData] = useState<DataState<ResponseFaqs>>({
    loading: true,
  });

  const handleChangePage = (page: number) => {
    setVariables((prev) => ({ ...prev, page }));
  };
  const [searchValue, setSearchValue] = useState("");
  const handleSearch = () => {
    setVariables((prev) => ({ ...prev, search: searchValue, page: 1 }));
  };

  const handleChangeFilter = ({ status }: { status?: string | null }) => {
    setVariables((prev) => ({ ...prev, status, page: 1 }));
  };

  useEffect(() => {
    if (variables !== undefined) {
      setSearchValue(variables?.search || "");
      ApiCMSWithSetData({
        query: QUERY_FAQS,
        variables,
        setData,
      });
    }
  }, [variables]);

  if (error) {
    return <p>Error : {error.message}</p>;
  }

  return (
    <MDBox my={3}>
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
        <MDButton
          variant="gradient"
          color="info"
          href={!permission.create ? "#" : `${path}/faqs/create`}
          disabled={!permission.create}
        >
          Nova Pergunta Frequente
        </MDButton>
        <MDButton
          variant="gradient"
          color="secondary"
          href={`${path}/faqs/pageEdit`}
          disabled={!permission.create}
        >
          Editar Página Estática
        </MDButton>
        <MDBox display="flex" gap={3} alignItems="baseline">
          <SearchInputControled
            onSubmitSearch={handleSearch}
            value={searchValue}
            onChangeValue={setSearchValue}
            disabled={loading}
          />
          <FilterMenu
            variables={variables}
            onChange={handleChangeFilter}
            buttonProps={{ loading, disabled: loading }}
          />
        </MDBox>
      </MDBox>
      <Card>
        <DataTableControled
          table={{
            columns: columnsTable({ path }),
            rows: data?.Faqs?.items || [],
          }}
          pagination={data?.Faqs?.paginationInfo}
          onChangePage={handleChangePage}
          loading={loading}
        />
      </Card>
    </MDBox>
  );
}

export default FaqsTable;
