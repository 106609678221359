import styled from "styled-components";
import { AccordionComponentProps, AccordionDetailsProps, AccordionSummaryProps } from "./types";

export const AccordionComponent = styled("div")<AccordionComponentProps>(
  {
    backgroundColor: "#FFFFFF",
    boxShadow: "0 0 20px rgba(37, 56, 123, 0.1)",
    borderRadius: "6px",
    overflow: "hidden",
    "& + &": {
      marginTop: "6px",
    },
  },
  ({ sx }) => ({ ...sx })
  // unstable_styleFunctionSx as InterpolationFunction<AccordionComponentProps>
);

export const AccordionSummary = styled("div")<AccordionSummaryProps>(
  {
    userSelect: "none",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    padding: "14px 16px",
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px",
    transition: "all .3s ease",
    svg: {
      width: "29px",
    },
  },
  ({ open, icon }) => ({
    backgroundColor: open ? "#364595" : "#FFFFFF",
    color: open ? "#FFFFFF" : "#141414",
    "& > svg": {
      transition: "all .3s ease",
      fill: open ? "#FFFFFF" : "#364595",
      transform: open ? (icon ? "rotate(180deg)" : "rotate(45deg)") : "rotate(0)",
    },
  })
  // unstable_styleFunctionSx as InterpolationFunction<AccordionSummaryProps>
);

export const AccordionDetails = styled("div")<AccordionDetailsProps>(
  {
    backgroundColor: "#F5F5F5",
    borderBottomLeftRadius: "6px",
    borderBottomRightRadius: "6px",
    ".content": {
      padding: "16px 17px",
      fontSize: "1rem",
      lineHeight: "1.625rem",
    },
  },
  ({ open }) => ({
    overflow: "hidden",
    transitionDuration: "0.3s",
    transitionProperty: "max-height",
    maxHeight: open ? "500rem" : "0",
    transitionTimingFunction: open
      ? `cubic-bezier(0.895, 0.03, 0.685, 0.22)`
      : `cubic-bezier(0.4, 0, 0.2, 1)`,
  }),
  ({ sx }) => ({ ...sx })
  // unstable_styleFunctionSx as InterpolationFunction<AccordionDetailsProps>
);
