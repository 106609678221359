import { useState } from "react";
import { groupBy } from "lodash";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalCustom from "components/CRPComponents/ModalCustom";
import { FormatedType } from "./types";
import { sortSubjectsFunction } from "./util";
import TableSubjects from "components/CRPComponents/TableSubjects";

type ModalVisualizationProps = {
  selectedItems: FormatedType[];
  open: boolean;
  onClose: () => void;
};

export default function ModalVisualization({
  selectedItems,
  open,
  onClose,
}: ModalVisualizationProps) {
  const [useGroup, setUseGroup] = useState(false);

  const getItems = (subjects: FormatedType[], useGroup?: boolean) => {
    if (useGroup) {
      return Object.entries(groupBy(subjects, "group")).map(([title, items]) => ({
        title: title,
        tableHeader: {
          key: "Disciplina",
          value: "Carga horária",
        },
        table: items.map((item) => ({
          key: item.name,
          value: `${item.hours}h`,
        })),
      }));
    }

    return subjects.map((item) => ({
      title: item.name,
      text: item.description,
    }));
  };

  return (
    <ModalCustom
      onClose={onClose}
      open={open}
      sx={{
        "& .head": {
          justifyContent: "center",
          width: "100%",
        },
        "& .css-aim41u": {
          marginLeft: "0 !important",
        },
      }}
    >
      <Stack padding="54px 1.5rem 1.5rem">
        <Stack flexDirection="row" gap="2rem" justifyContent="space-evenly">
          <Stack justifyContent="center" flexDirection="column">
            <Typography variant="body1" align="center" gutterBottom>
              Agrupados
            </Typography>
            <Typography
              variant="subtitle2"
              gutterBottom
              sx={{ paddingBottom: "1rem" }}
              noWrap={false}
            >
              Usado nos templates de Graduação, Pós Graduação e Técnico. <br />
              (graduacao, pos-graduacao, tecnicos)
            </Typography>
            <TableSubjects content={getItems(selectedItems?.sort(sortSubjectsFunction), true)} />
          </Stack>
          <hr />
          <Stack justifyContent="center" flexDirection="column">
            <Typography variant="body1" align="center" gutterBottom>
              Sem Grupo
            </Typography>
            <Typography
              variant="subtitle2"
              gutterBottom
              sx={{ paddingBottom: "1rem" }}
              noWrap={false}
            >
              Usado nos templates de Extensão e Cursos Livres. <br />
              (extensao, cursos-livres)
            </Typography>
            <TableSubjects content={getItems(selectedItems?.sort(sortSubjectsFunction), false)} />
          </Stack>
        </Stack>
      </Stack>
    </ModalCustom>
  );
}
