import { useState, Fragment } from "react";
import MDButton, { MDButtonProps } from "components/MDButton";
import { Modal, ModalProps } from "components/Modal";
import MDTypography from "components/MDTypography";
import Row from "components/Row";
import Col from "components/Col";

export function DeleteButton<T>({
  handleDelete,
  propDelete,
  label = "Deletar",
  color = "error",
  children = label,
  modalProps = {},
  modalTitle = `Tem certeza que deseja ${label.toLowerCase()}`,
  cancelLabel = "Cancelar",
  warning = "Essa operação não pode ser desfeita",
  ...props
}: DeleteButtonProps<T>) {
  const [openConfirm, setOpenConfirm] = useState(false);
  const handleOpenConfirm = () => setOpenConfirm(true);
  const handleCloseConfirm = () => setOpenConfirm(false);

  if (!propDelete) return null;
  return (
    <Fragment>
      <MDButton color={color} modalProps={modalProps} onClick={handleOpenConfirm}>
        {children}
      </MDButton>
      <Modal
        {...modalProps}
        title={modalTitle}
        open={openConfirm}
        onCloseButton={handleCloseConfirm}
      >
        <Col>
          <MDTypography>{warning}</MDTypography>
          <Row width="100%" justifyContent="space-between" pt={2}>
            <MDButton color="success" onClick={handleCloseConfirm} loading={props.loading}>
              {cancelLabel}
            </MDButton>
            <MDButton
              color="error"
              onClick={() => handleDelete(propDelete).then(() => handleCloseConfirm())}
              loading={props.loading}
            >
              {label}
            </MDButton>
          </Row>
        </Col>
      </Modal>
    </Fragment>
  );
}

export interface DeleteButtonProps<T> extends Omit<MDButtonProps, "onClick"> {
  propDelete: T;
  handleDelete?: (propDelete: T) => Promise<void>;
  modalProps?: Omit<ModalProps, "open" | "onCloseButton" | "onClose" | "children" | "title">;
  modalTitle?: string;
  label?: string;
  cancelLabel?: string;
  warning?: string;
}

export default DeleteButton;
