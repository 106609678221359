import { useEffect, useState } from "react";
import { SchemaData, UseGetItemProps } from "./types";
import UISchemaJson from "../schema/UISchemaEdit.json";
import templateSchema from "../schema/SchemaJson.json";
import { GET_ITEM_QUERY, GetItemGql } from "../queries/getItem";
import { removeNullValues } from "utils/removeNullValues";
import { ApiCourseRequest } from "data/queries/ApiCourse";

const useGetItem = ({ setState, setTemplate, queryParam }: UseGetItemProps<SchemaData>) => {
  const [responseData, setResponseData] = useState<SchemaData>();

  useEffect(() => {
    if (!!queryParam) {
      setState((props) => ({ ...props, messageError: undefined, criticalError: undefined }));
      ApiCourseRequest<GetItemGql>({
        query: GET_ITEM_QUERY,
        variables: { id: queryParam },
      })
        .then((response) => {
          if (!response?.data?.data?.Course) {
            throw new Error("Não encontrado", {
              cause: { response: response?.data || response },
            });
          }

          const data = response.data.data?.Course;
          console.log({ courseGroupId: data?.courseGroup?.id });

          setResponseData(
            removeNullValues({
              name: data?.name,
              title: data?.title,
              openForSubscriptions: data?.openForSubscriptions,
              status: data?.status,
              slug: data?.slug,
              keywords: data?.keywords,
              courseGroupId: data?.courseGroup?.id,
              modalityId: data?.modality?.id,
              educationalUnitId: data?.educationalUnit?.id,
              courseLoadHours: data?.courseLoadHours,
              semesters: data?.semesters,
              shift: data?.shift,
              startDate: !data?.startDate ? null : data.startDate.split("T")[0],
              immediateStart: data?.immediateStart,
              coordinatorId: data?.coordinator?.id,
              facultyMembersIds: data?.facultyMembers.map(({ id }) => id),
              subjectsIds: data?.subjects.map(({ id }) => id),
              tagsIds: data?.tags.map(({ id }) => id),
              contentJson: data?.info?.contentJson ?? "{ }",

              //new
              imageUrl: data?.imageUrl,
              description: data?.description,
              institution: data?.institution,
            })
          );
        })
        .catch((error) => {
          console.log({ error });

          setState((prev) => ({
            ...prev,
            criticalError: error?.response?.data?.errors?.[0] || error,
          }));
        });
    }
  }, [queryParam]);

  useEffect(() => {
    if (templateSchema && responseData) {
      setTemplate({
        schemaJson: templateSchema,
        UISchema: UISchemaJson,
      });

      setState({
        data: responseData,
      });
    }
  }, [templateSchema, responseData]);
};

export default useGetItem;
