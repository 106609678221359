import { SchemaData, UseUpdateProps } from "./types";
import { useCallback } from "react";
import { JsonFormsStateProps } from "components/CRPComponents/JsonForm/JsonFormsComponent/types";
import { UPDATE_QUERY, UpdateGql } from "../queries/update";
import { ApiAuthRequest } from "data/ApiAuth";

export default function useUpdate({ queryParam }: UseUpdateProps) {
  const handleSave = useCallback(
    async (state: JsonFormsStateProps<SchemaData>) => {
      const data = state?.data;

      const response = await ApiAuthRequest<UpdateGql>({
        query: UPDATE_QUERY,
        variables: {
          ...data,
          birthDate: new Date(data.birthDate),
          permissionsIds: data?.permissionsIds ?? [],
        },
      });

      if (response?.data?.errors) {
        console.log(response?.data?.errors);
        throw response.data.errors[0];
      }
      if (!response?.data?.data) {
        throw new Error("Ops. Sem resposta!");
      }
      return response;
    },
    [queryParam]
  );

  return {
    handleSave,
  };
}
