import Box from "@mui/system/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { BasicAccordion } from "../Accordion";

interface ItemContent {
  title: string;
  tableHeader?: {
    key: string;
    value: string;
  };
  table?: {
    key: string;
    value: string;
  }[];
  text?: string;
}

const TableSubjects = ({ content }: { content: ItemContent[] }) => {
  const getTable = (item: ItemContent) => {
    if (!item.table || !item.tableHeader) return null;

    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: "100%" }} aria-label="simple table">
          <TableHead sx={{ display: "table-header-group" }}>
            <TableRow>
              <TableCell>{item.tableHeader.key}</TableCell>
              <TableCell align="right">{item.tableHeader.value}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {item.table.map((row) => (
              <TableRow key={row.key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.key}
                </TableCell>
                <TableCell align="right">{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const accordionContent = content.map((item) => {
    const table = getTable(item);
    const text = (
      <Box
        sx={{
          background: "#F8F8FB",
        }}
      >
        <Box
          component={"p"}
          sx={{
            color: "#7D7C83",
            fontSize: "1rem",
            lineHeight: "1.5rem",
            fontWeight: 100,
            margin: 0,
            padding: "1rem",
          }}
        >
          {item.text}
        </Box>
      </Box>
    );
    return {
      title: {
        component: <Typography variant="h6">{item?.title}</Typography>,
      },
      content: {
        component: table || text,
      },
    };
  });

  return (
    <Box>
      <BasicAccordion items={accordionContent} />
    </Box>
  );
};

export default TableSubjects;
