// Layout
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import FormPage from "components/CRPComponents/Forms/FormPage";
import { Permissions } from "../permissions";
import { slugsStaticPagesEnum } from "../../utils/slugsStaticPagesEnum";

function PoliciesPage(): JSX.Element {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FormPage
        pageName={slugsStaticPagesEnum.policies}
        actionsPermission={{
          createPermision: Permissions.create,
          deletePermision: Permissions.delete,
          updatePermision: Permissions.update,
        }}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default PoliciesPage;
