import { Fragment, useCallback, useEffect, useState } from "react";
import {
  Stack,
  Box,
  Typography,
  IconButton,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MDButton from "components/MDButton";
import AddIcon from "@mui/icons-material/Add";
import Props, { FormatedType } from "./types";
import useData from "./useData";
import TableSubject from "./ListSubject";
import ModalVisualization from "./ModalVisualization";

const btnStyle = {
  border: "1px solid #7b809a",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 1,
};

function SelectMultiple({
  data,
  handleChange,
  path,
  label,
  required,
  errors,
  Modal,
  requestItem,
  formatItem,
  enabled,
  uischema,
}: Props) {
  const [open, setOpen] = useState(false);
  const [openVisualization, setOpenVisualization] = useState(false);
  const { selectedItems, setSelectedItems, loading } = useData({
    ids: data,
    requestItem,
    formatItem,
  });

  useEffect(() => {
    if (
      (data !== undefined && !Array.isArray(data)) ||
      data?.some((item) => typeof item !== "number" && typeof item !== "string")
    ) {
      throw new Error("Invalid param, must be an Array of number or string");
    }
  }, [data]);

  useEffect(() => {
    if (selectedItems.length >= (uischema?.options?.max ?? Infinity)) {
      setOpen(false);
    }
  }, [selectedItems]);

  const onChange = ({ id, name, ...props }: FormatedType) => {
    if (!id || !name || !enabled) {
      console.log(id, name);
      return;
    }

    const remove = (data as any)?.includes(id);
    const newData = remove
      ? [...selectedItems.filter((_item) => _item.id !== id)]
      : [...selectedItems, { id, name, ...props }];
    handleChange(path, newData.length ? newData.map((item) => item.id) : undefined);

    setSelectedItems(newData);
  };

  const handleOpen = useCallback(() => setOpen((old) => (enabled ? !old : false)), []);

  if (loading) {
    return (
      <Stack alignItems={"center"}>
        <CircularProgress sx={{ color: "#7b809a" }} />
      </Stack>
    );
  }

  return (
    <Fragment>
      <Stack gap={1} margin={"10px 0"}>
        <Box
          component={"p"}
          sx={{
            fontFamily: "Roboto",
            fontSize: "0.9rem",
            fontWeight: "400",
            lineHeight: "1.4375em",
            letterSpacing: "0.00938em",
            padding: 0,
            position: "relative",
            color: !!errors ? "#F44335" : "#7b809a",
          }}
          onClick={() => {
            console.log(selectedItems);
          }}
        >
          {label}
          {required && " *"}
          {selectedItems?.length > 0 && (
            <IconButton
              sx={{ marginX: "5px" }}
              disabled={!enabled}
              aria-label="Visualizar"
              size="medium"
              onClick={() => setOpenVisualization(true)}
            >
              <VisibilityIcon />
            </IconButton>
          )}
        </Box>
        <TableSubject selectedItems={selectedItems || []} onChange={onChange} enabled={enabled} />
        <MDButton
          sx={btnStyle}
          onClick={handleOpen}
          disabled={!enabled || selectedItems.length >= (uischema?.options?.max ?? Infinity)}
        >
          Adicionar
          <AddIcon />
        </MDButton>
      </Stack>
      {!!errors && <FormHelperText error>{errors}</FormHelperText>}
      {enabled && (
        <Modal open={open} ids={data ?? []} onChange={onChange} handleOpen={handleOpen} />
      )}
      {
        <ModalVisualization
          open={openVisualization}
          onClose={() => setOpenVisualization(false)}
          selectedItems={selectedItems || []}
        />
      }
    </Fragment>
  );
}

export default SelectMultiple;
