import { useState } from "react";
import { AccordionComponent } from "./styles";
import { AccordionProps } from "./types";
import { AccordionSummaryComponet } from "./AccordionSummary";
import { AccordionDetailsComponent } from "./AccordionDetails";

export const Accordion = ({ sx, content, title, icon, hide = false }: AccordionProps) => {
  const [hidden, setHidden] = useState(false);

  const _handleToggleHidden = () => {
    setHidden(!hidden);
  };

  if (hide) return null

  return (
    <AccordionComponent sx={sx}>
      <AccordionSummaryComponet
        onClick={_handleToggleHidden}
        hidden={hidden}
        sx={title.sx}
        icon={icon}
      >
        {title.component}
      </AccordionSummaryComponet>
      <AccordionDetailsComponent hidden={hidden} sx={content.sx}>
        {content.component}
      </AccordionDetailsComponent>
    </AccordionComponent>
  );
};
