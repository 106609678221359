import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GenericTable from "components/CRPComponents/Tables/GenericTable";
import DefaultCell from "components/CRPComponents/Tables/components/DefaultCell";
import LinkCell from "components/CRPComponents/Tables/components/LinkCell";
import { ListProps } from "./types";
import { GET_ALL_QUERY, GetAllGql, OrderStatusType, OrderStatusTypeEnum } from "./queries/getAll";
import { ApiFinancialWithSetData } from "data/queries/ApiFinancial";
import { formatPrice } from "utils/formatPrice";
import { SyntheticEvent, useCallback, useState } from "react";
import MDInput from "components/MDInput";
import { IconButton, MenuItem, Stack, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { formatDate, generateDateRange } from "./utils";
import CloseIcon from "@mui/icons-material/Close";
import { UseGetAllOrders } from "./hooks/UseGetAllOrders";

type Filters = {
  creationDate: string | undefined;
  invoicedDate: string | undefined;
  status: string | undefined;
  query: string | undefined;
};

type OrderBy = {
  OrderField: string;
  OrderType: "asc" | "desc";
};

const dates = {
  day: generateDateRange("1 Day"),
  week: generateDateRange("7 Day"),
  month: generateDateRange("1 Month"),
  year: generateDateRange("1 Year"),
};

function List(props: ListProps) {
  const [filters, setFilters] = useState<Filters>({
    creationDate: dates.month,
    invoicedDate: undefined,
    status: undefined,
    query: undefined,
  });
  const [orderBy, setOrderBy] = useState<OrderBy>({
    OrderField: "creationDate",
    OrderType: "desc",
  });
  const useOrders = UseGetAllOrders();

  const handleFilters = useCallback(
    (key: keyof typeof filters, data: string) =>
      setFilters((old) => ({
        ...old,
        [key]: data,
      })),
    []
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={"10px"}
        sx={{
          padding: "1rem",
          background: "white",
          borderRadius: "6px",
          boxShadow:
            "0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06)",
        }}
      >
        <Stack gap={"20px"}>
          <TextField
            label="Busque por nome do cliente, CPF, Email ou id do pedido"
            InputProps={{
              endAdornment: (
                <>
                  {filters.query && (
                    <IconButton
                      onClick={(evt: SyntheticEvent) => {
                        handleFilters("query", undefined);
                        evt.currentTarget.parentElement.querySelector("input").value = "";
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={(evt: SyntheticEvent) =>
                      handleFilters(
                        "query",
                        evt.currentTarget.parentElement.querySelector("input").value
                      )
                    }
                  >
                    <SearchIcon />
                  </IconButton>
                </>
              ),
            }}
          />
          <Stack direction={"row"} alignItems={"center"} gap={"6px"}>
            <MDInput
              value={filters.status}
              label="Status"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleFilters("status", e.target.value)
              }
              sx={{ width: "200px" }}
              select
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              <MenuItem value={"waitingForSellersConfirmation"}>
                À espera de confirmação de vendedor
              </MenuItem>
              <MenuItem value={"paymentPending"}>Pagamento pendente</MenuItem>
              <MenuItem value={"paymentApproved"}>Pagamento aprovado</MenuItem>
              <MenuItem value={"readyForHandling"}>Pronto para ser manuseado</MenuItem>
              <MenuItem value={"handling"}>Em manuseamento</MenuItem>
              <MenuItem value={"invoiced"}>Faturado</MenuItem>
              <MenuItem value={"canceled"}>Cancelado</MenuItem>
            </MDInput>
            <MDInput
              value={filters.creationDate}
              label="Data de criação"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleFilters("creationDate", e.target.value)
              }
              sx={{ width: "200px" }}
              select
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              <MenuItem value={dates.day}>1 dia atrás</MenuItem>
              <MenuItem value={dates.week}>1 semana atrás</MenuItem>
              <MenuItem value={dates.month}>1 mês atrás</MenuItem>
              <MenuItem value={dates.year}>1 ano atrás</MenuItem>
            </MDInput>
            <MDInput
              value={filters.invoicedDate}
              label="Data de faturamento"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleFilters("invoicedDate", e.target.value)
              }
              sx={{ width: "200px" }}
              select
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              <MenuItem value={dates.day}>1 dia atrás</MenuItem>
              <MenuItem value={dates.week}>1 semana atrás</MenuItem>
              <MenuItem value={dates.month}>1 mês atrás</MenuItem>
              <MenuItem value={dates.year}>1 ano atrás</MenuItem>
            </MDInput>
            <MDInput
              value={orderBy.OrderField}
              label="Filtrar por"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setOrderBy((old) => ({ ...old, OrderField: e.target.value }))
              }
              sx={{ width: "200px" }}
              select
            >
              <MenuItem value={"creationDate"}>Data de criação</MenuItem>
              <MenuItem value={"orderId"}>Id do pedido</MenuItem>
              <MenuItem value={"items"}>Quantidade de items</MenuItem>
              <MenuItem value={"totalValue"}>Valor total</MenuItem>
              <MenuItem value={"origin"}>Origem</MenuItem>
            </MDInput>
            <MDInput
              value={orderBy.OrderType}
              label="Ordenação"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setOrderBy((old) => ({ ...old, OrderType: e.target.value as "desc" | "asc" }))
              }
              sx={{ width: "200px" }}
              select
            >
              <MenuItem value={"asc"}>Ascendente</MenuItem>
              <MenuItem value={"desc"}>Descendente</MenuItem>
            </MDInput>
          </Stack>
        </Stack>
      </Stack>
      <GenericTable<GetAllGql>
        initialVariables={{
          perPage: 10,
          page: 1,
          ...filters,
          orderBy,
        }}
        dowloadSvgJson={useOrders.responses}
        allFildsSvgh={useOrders.allFilds}
        path={`${props.path}/pedidos`}
        query={GET_ALL_QUERY}
        formatRows={(data) => data.Orders.list}
        formatPagination={(data) => ({
          page: data.Orders.paging.currentPage,
          perPage: data.Orders.paging.perPage,
          totalData: data.Orders.paging.total,
          totalPages: data.Orders.paging.pages,
        })}
        title=""
        ApiSetData={ApiFinancialWithSetData}
        hiddenSearch
        useDefaultFilter={false}
        columnsTable={[
          {
            Header: "id",
            accessor: "orderId",
            Cell: ({ value }: any) => (
              <LinkCell href={`${props.path}/pedidos/${value}`} value={value} />
            ),
          },
          {
            Header: "Cliente",
            accessor: "clientName",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "Valor total",
            accessor: "totalValue",
            Cell: ({ value }: any) => <DefaultCell value={formatPrice(value / 100)} />,
          },
          {
            Header: "Quantidade de itens",
            accessor: "totalItems",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: "status",
            accessor: "status",
            Cell: ({ value }: { value: OrderStatusType }) => (
              <DefaultCell value={OrderStatusTypeEnum[value]} />
            ),
          },
          {
            Header: "Data do pedido",
            accessor: "creationDate",
            Cell: ({ value }: any) => <DefaultCell value={formatDate(value)} />,
          },
        ]}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default List;
