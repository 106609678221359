import axios, { AxiosResponse } from "axios";
import { getUserToken } from "utils/getToken";

export const ApiFinancialGraphql = process.env.REACT_APP_API_FINANCIAL_URL;

export const ApiFinancialInstance = axios.create({
  baseURL: ApiFinancialGraphql,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: getUserToken(),
  },
  method: "POST",
});

export async function ApiFinancialRequest<responseDataType = any>({
  query,
  variables,
}: {
  query: string;
  variables?: { [key: string]: any };
}): Promise<AxiosResponse<{ data?: responseDataType; errors?: Error[] }>> {
  return ApiFinancialInstance.request<{
    data?: responseDataType;
    errors?: Error[];
  }>({
    data: {
      query,
      variables,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: getUserToken(),
    },
  });
}

export interface DataState<DataType = any> {
  loading?: boolean;
  error?: Error;
  data?: DataType;
}

export async function ApiFinancialWithSetData<T = any>({
  query,
  variables,
  setData,
}: {
  query: string;
  variables?: { [key: string]: any };
  setData: (data: DataState<T>) => void;
}): Promise<DataState<T>> {
  return ApiFinancialRequest<T>({ query, variables })
    .then((response) => {
      if (!response?.data?.data) {
        const respError: DataState<T> = {
          loading: false,
          error: response?.data?.errors?.[0] || new Error("mediaFiles not found"),
          data: undefined,
        };
        setData(respError);
        return respError;
      }
      const respSuccess: DataState<T> = {
        error: undefined,
        loading: false,
        data: response.data.data,
      };
      setData(respSuccess);
      return respSuccess;
    })
    .catch((error) => {
      console.error(error);
      const respError: DataState<T> = {
        loading: false,
        error,
        data: undefined,
      };
      setData(respError);
      return respError;
    });
}
