import { AccordionDetails } from "./styles";
import { AccordionDetailsComponentProps } from "./types";

export const AccordionDetailsComponent = ({
  children,
  hidden,
  sx,
}: AccordionDetailsComponentProps) => {
  return (
    <AccordionDetails aria-hidden={hidden} open={hidden} sx={sx}>
      <div className="content">{children}</div>
    </AccordionDetails>
  );
};
